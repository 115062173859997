.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.circles li {
  position: fixed;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.4);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
  background: rgba(255, 228, 196, 0.4);
}

.circles li:nth-child(2) {
  left: 10%;
  width: 60px;
  height: 60px;
  animation-delay: 2s;
  animation-duration: 12s;
  background: rgba(0, 255, 255, 0.4);
}

.circles li:nth-child(3) {
  left: 70%;
  width: 45px;
  height: 45px;
  animation-delay: 4s;
  background: rgba(127, 255, 212, 0.5);
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
  background: rgba(0, 206, 209, 0.4);
}

.circles li:nth-child(5) {
  left: 65%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
  background-color: rgba(255, 250, 240, 0.4);
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
  background-color: rgba(255, 215, 0, 0.4);
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 35px;
  height: 35px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 35px;
  height: 35px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
  background: rgb(255, 105, 180, 0.4);
}

.circles li:nth-child(11) {
  left: 5%;
  width: 110px;
  height: 110px;
  animation-delay: 11s;
  animation-duration: 25s;
}

@keyframes animate {
  0% {
    transform: translate(10px, 0) rotate(0deg) scaleX(1) scaleY(1);
    opacity: 1;
  }

  60% {
    transform: translate(0, -600px) rotate(500deg) scaleX(0.8) scaleY(0.8);
    opacity: 0.6;
  }

  100% {
    transform: translate(-10px, -1000px) rotate(720deg) scaleX(1) scaleY(1);
    opacity: 0.5;
  }
}
